import React from 'react'
import axios from 'axios'

import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import AppBar from '@material-ui/core/AppBar'
import Switch from '@material-ui/core/Switch'

import logo from './images/logo.png'
import './stylesheets/app.sass'

import Form from './Form'
import ContractSetup from './ContractSetup'
import Contract from './Contract'

const priceText = "(12) Naručilac posla se obavezuje da isplatu honorara iz člana 11. Ugovora izvrši na sledeći način:\r\
a) Iznos od xxx nakon prijema koncepta i odobrenja od strane naručioca.\r\
b) Preostali deo honorara u iznosu od xxx Naručilac posla isplatiće po prijemu dokumentacije iz člana 5. ovog Ugovora i odobrenja od strane Naručioca."

const priceTextEn = "(12) The Client undertakes to make the payment of the remuneration referred to in Article 11  hereof in the following way:\r\
a) The amount of xxx after the receipt of the concept and its approval by the Client.\r\
b) The Client shall pay the remaining portion of the remuneration amounting to xxx following the receipt of the documentation referred to in Article 5 hereof and its approval by the Client."



function App() {

  const [state, setState] = React.useState({
    step: 1,
    theme: 'dark',
    data: {price_text: priceText, price_text_en: priceTextEn},
    is_logged_in: true
  });

  const [options, setOptions] = React.useState([]);

  // const [author, setAuthor] = React.useState("");

  const [authors, setAuthors] = React.useState([]);

  const theme = createMuiTheme({
    palette: {
      type: state.theme,
    },
  })

  const opt = () => {    
    //http://localhost:3001
    axios.get('/status')
    .then(function (response) {
      setOptions(response.data.statuses);
    })
  }

  const get_authors = () => {    
    //http://localhost:3001
    axios.get('/authors')
    .then(function (response) {
      setAuthors(response.data.authors);
    })
  }

  React.useEffect(() => {
    opt();
    get_authors();
    //http://localhost:3001
    axios.get('/is_logged_in')
    .then(function (response) {
      setState({ is_logged_in: response.data, step: 1, theme: 'dark', data: state.data});
    }).catch(function (error) { console.log(error) });
  }, [])

  const switchTheme = () => event => {
    setState({ theme: event.target.checked ? 'dark' : 'light', step: state.step, data: state.data })
  }

  const step = (step,reset) => {
    setState({ step: step, theme: state.theme, data: reset ? {} : state.data })
  }

  const handleChange = () => event => {    
    let value = event.target.name === 'resident' ?  event.target.checked : event.target.value
    if(event.target.name === 'lang'){
      setState({ step: state.step, theme: state.theme, data: { ...state.data, [event.target.name]: value, price_text: event.target.value === 'en' ? priceTextEn : priceText} })
    } else {
      setState({ step: state.step, theme: state.theme, data: { ...state.data, [event.target.name]: value} })
    }
  }


  const autocomplete = () => event => {
    if (event.target.textContent !== ""){            
      //http://localhost:3001
      axios.get('/author/' + event.target.textContent)
      .then(function (response) {
        setState({ step: state.step, theme: state.theme, data: { ...state.data,
           gender: response.data.author.gender,
           resident: response.data.author.resident,
           name: response.data.author.full_name,
           email: response.data.author.email,
           mb: response.data.author.passport,
           address: response.data.author.address,
           phone: response.data.author.phone,
           bank_name: response.data.author.bank_name,
           bank_address: response.data.author.bank_address,
           iban: response.data.author.iban,
           status: response.data.author.status_id,
           swift: response.data.author.swift,
           title: response.data.author.job_name,
           contact_email: response.data.author.email2,
           autocomplete_author: response.data.author
           }})
      })
    } else {
    setState({ step: state.step, theme: state.theme, data: {}})
    }
  }

  let screen = ""
  switch (state.step) {
    case 1:
      screen = <Form data={state.data} authors={authors} options={options} next={() => step(2)} handleChange={handleChange} autocomplete={autocomplete} />
      break;
    case 2:
      screen = <ContractSetup data={state.data} handleChange={() => handleChange()} back={() => step(1)} next={() => step(3)} />
      break;
    case 3:
      screen = <Contract data={state.data} back={() => step(2)} reset={() => step(1,true)} />
      break;
    default:
      screen = ""
  }

  if (state.is_logged_in === false) {
    window.location.href = '//ugovori.rosalux.rs/admin/login/';
    return null;
  }

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="static" color="inherit">
        <img className="logo" src={logo} alt="RLS" />
        <div style={{position: 'absolute', right: 10}}>
        <small style={{fontSize: 14, color: 'gray'}}>Tamna tema </small>
        <Switch
           checked={state.theme === 'dark'}
           onChange={switchTheme()}
         />
        </div>
     </AppBar>
      <Container component="main" maxWidth="md">
        <div>
          {screen}
        </div>
        <Grid container style={{marginTop: 100, marginBottom: 20}}>
          <Grid item xs={10} sm={10}><a href="/admin">Ugovori</a></Grid>
          <Grid item xs={2} sm={2} style={{color: 'gray'}}>Slobodna domena 2020</Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  )
}

export default App
