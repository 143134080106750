import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import Autocomplete from '@material-ui/lab/Autocomplete'

function Form(props) {

  return (
    <form noValidate>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item>
          <Typography component="h1" variant="h5">Author</Typography>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="combo-box-demo"
            options={props.authors}
            getOptionLabel={(option) => option.label}
            getOptionSelected={(option, value) => option.id === value.id}
            style={{ width: 500 }}
            onChange={props.autocomplete()}
            value={props.data.autocomplete_author || null}
            renderInput={(params) => <TextField {...params} label="Author" variant="outlined" />}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <FormLabel component="legend">Spol / Gender</FormLabel>
          <RadioGroup aria-label="gender" name="gender" value={props.data.gender || ''} onChange={props.handleChange()}>
            <FormControlLabel value="female" control={<Radio />} label="Ženski / Female" />
            <FormControlLabel value="male" control={<Radio />} label="Muški / Male" />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} sm={12}>
          <InputLabel id="select-label">Citizen of RS / Državljanin RS</InputLabel>
          <Switch
            name="resident"
            checked={props.data.resident || false}
            onChange={props.handleChange()}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="name"
            value={props.data.name || ''}
            variant="outlined"
            label="Ime i prezime / Name and surname"
          required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="mb"
            value={props.data.mb || ''}
            variant="outlined"
            label={props.data.resident ? "Matični broj / ID" : "Broj pasoša / Passport ID"}
          required fullWidth />
        </Grid>
        {props.data.resident &&
          <Grid item xs={12} sm={12}>
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="select-label">Status</InputLabel>
              <Select labelId="select-label" name="status" value={props.data.status || ''}  labelWidth={80} onChange={props.handleChange()}>
                {props.options.map(option => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="address"
            value={props.data.address || ''}
            variant="outlined"
            label="Adresa / Address"
          required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="email"
            value={props.data.email || ''}
            variant="outlined"
            label="E-mail"
          required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="phone"
            value={props.data.phone || ''}
            variant="outlined"
            label="Mobitel / Phone"
          required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="bank_name"
            value={props.data.bank_name || ''}
            variant="outlined"
            label="Naziv banke / Bank name"
          required fullWidth />
        </Grid>
        {!props.data.resident &&
          <Grid item xs={12} sm={12}>
            <TextField
              onChange={props.handleChange()}
              name="bank_address"
              value={props.data.bank_address || ''}
              variant="outlined"
              label="Adresa banke / Bank address"
            required fullWidth />
          </Grid>
        }
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="iban"
            value={props.data.iban || ''}
            variant="outlined"
            label="IBAN"
          required fullWidth />
        </Grid>
        {!props.data.resident &&
          <Grid item xs={12} sm={12}>
            <TextField
            onChange={props.handleChange()}
            name="swift"
            value={props.data.swift || ''}
            variant="outlined"
            label="SWIFT"
            required fullWidth />
        </Grid>
        }
        <Grid item>
          <Button type="submit" variant="contained" color="primary" onClick={props.next}>
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Form
