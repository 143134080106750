import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import AnnexBlock from './AnnexBlock'


function ContractSetup(props) {

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item>
          <Typography component="h1" variant="h5">Project</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="title"
            value={props.data.title}
            variant="outlined"
            label="Naziv autorskog dela / Service title (SRB)"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="project"
            value={props.data.project}
            variant="outlined"
            label="Naziv projekta / Project title (SRB)"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="title_en"
            value={props.data.title_en}
            variant="outlined"
            label="Naziv autorskog dela / Service title (ENG)"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="project_en"
            value={props.data.project_en}
            variant="outlined"
            label="Naziv projekta / Project title (ENG)"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="date"
            value={props.data.date}
            variant="outlined"
            label="Rok izvršenja / Deadline"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="price"
            value={props.data.price}
            variant="outlined"
            label="Iznos, valuta, bruto ili neto / Amount, currency, gross or netto"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="price_text"
            value={props.data.price_text}
            variant="outlined"
            label="Iznos i rate isplate / Amount and installment rate (SRB)"
            multiline
            rows={7}
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="price_text_en"
            value={props.data.price_text_en}
            variant="outlined"
            label="Iznos i rate isplate / Amount and installment rate (ENG)"
            multiline
            rows={7}
            required fullWidth />
        </Grid>

        <AnnexBlock data={props.data} handleChange={() => props.handleChange()} />

        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="contract_date"
            value={props.data.contract_date}
            variant="outlined"
            label="Datum ugovora / Contract date"
            required fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={props.handleChange()}
            name="contact_email"
            value={props.data.contact_email}
            variant="outlined"
            label="Kontakt email / Contact email"
            required fullWidth />
        </Grid>
        <Grid item xs={2} sm={2}>
          <Button type="submit" variant="contained" color="primary" onClick={props.back}>
            Back
          </Button>
        </Grid>
        <Grid item xs={2} sm={2}>
          <Button type="submit" variant="contained" color="secondary" onClick={props.next}>
            Next
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default ContractSetup
