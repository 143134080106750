import React from 'react'
import axios from 'axios'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

function Contract(props) {

  const [state, setState] = React.useState({status: ''});

  const send = () => event => {
    setState({status: ' ... '});
    //http://localhost:3001
    axios.post('/generate_pdf', {data: props.data})
    .then(function (response) {
      setState({status: response.data.status});
   }).catch(function (error) { setState({status: 'Došlo je do greške: ' + error}); });
  }

  return (
    <form>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={12}>
          <Typography component="h1" variant="h5">UGOVOR</Typography>
          <br />
          <Typography color="error" component="h2" variant="h4">{state.status}</Typography>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Button variant="contained" color="primary" onClick={props.back}>
            Back
          </Button>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Button variant="contained" color="secondary" onClick={props.reset}>
            Reset
          </Button>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Button variant="contained" onClick={send()}>
            PDF
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default Contract
