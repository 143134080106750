import React from 'react'

import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

function AnnexBlock(props) {

  const [activeA, setActiveA] = React.useState(props.data.description_2);
  const [activeB, setActiveB] = React.useState(props.data.description_3);
  const [activeC, setActiveC] = React.useState(props.data.description_4);
  const [activeD, setActiveD] = React.useState(props.data.description_5);

  const toggleAnnexA = () => {
    activeA ? setActiveA(false) : setActiveA(true);
  }
  
  const toggleAnnexB = () => {
    activeB ? setActiveB(false) : setActiveB(true);
  }

  const toggleAnnexC = () => {
    activeC ? setActiveC(false) : setActiveC(true);
  }

  const toggleAnnexD = () => {
    activeD ? setActiveD(false) : setActiveD(true);
  }

  return (
    <>
    <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="annex_1_title"
          defaultValue="Annex 1: Opis posla / Job Description"
          value={props.data.annex_1_title}
          variant="outlined"
          label="Naslov Annexa /Annex title (SRB/ENG)"
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description"
          value={props.data.description}
          variant="outlined"
          label="Opis/Description (SRB)"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_en"
          value={props.data.description_en}
          variant="outlined"
          label="Job description"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Divider width="96%" style={{margin: '10px 2%'}} />
      <Button onClick={toggleAnnexA} variant={activeA ? "contained" : "outlined"}>Annex 2</Button>

      {/* -------------- 2 ------------------------- */}

      {activeA &&
      <>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="annex_2_title"
          defaultValue="Annex 2: Opis posla / Job Description"
          value={props.data.annex_2_title}
          variant="outlined"
          label="Naslov Annexa/Annex title (SRB/ENG)"
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_2"
          value={props.data.description_2}
          variant="outlined"
          label="Opis/Description (SRB)"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_en_2"
          value={props.data.description_en_2}
          variant="outlined"
          label="Job description"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Button onClick={toggleAnnexB} variant={activeB ? "contained" : "outlined"}>Annex 3</Button>
      </>
      }
      
      {/* -------------- 3 ------------------------ */}
      
      { activeB && 
      <>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="annex_3_title"
          defaultValue="Annex 3: Opis posla / Job Description"
          value={props.data.annex_3_title}
          variant="outlined"
          label="Naslov Annexa/Annex title (SRB/ENG)"
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_3"
          value={props.data.description_3}
          variant="outlined"
          label="Opis/Description (SRB)"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_en_3"
          value={props.data.description_en_3}
          variant="outlined"
          label="Job description"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Button onClick={toggleAnnexC} variant={activeC ? "contained" : "outlined"}>Annex 4</Button>
      </>
      }
      
      {/* -------------- 4 ---------------------- */}
      
      { activeC && 
      <>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="annex_4_title"
          defaultValue="Annex 4: Opis posla / Job Description"
          value={props.data.annex_4_title}
          variant="outlined"
          label="Naslov Annexa/Annex title (SRB/ENG)"
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_4"
          value={props.data.description_4}
          variant="outlined"
          label="Opis/Description (SRB)"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_en_4"
          value={props.data.description_en_4}
          variant="outlined"
          label="Job description"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Button onClick={toggleAnnexD} variant={activeD ? "contained" : "outlined"}>Annex 5</Button>
      </>
      }
      
      
      {/* --------------- 5 ----------------------- */}
      
      { activeD && 
      <>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="annex_5_title"
          defaultValue="Annex 5: Opis posla / Job Description"
          value={props.data.annex_5_title}
          variant="outlined"
          label="Naslov Annexa/Annex title (SRB/ENG)"
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_5"
          value={props.data.description_5}
          variant="outlined"
          label="Opis/Description (SRB)"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Grid item xs={12} sm={12}>
        <TextField
          onChange={props.handleChange()}
          name="description_en_5"
          value={props.data.description_en_5}
          variant="outlined"
          label="Job description"
          multiline
          rows={12}
          required fullWidth />
      </Grid>
      <Divider width="96%" style={{ margin: '10px 2%' }} />
      </>
      }
    </>     
  )
}

export default AnnexBlock
